import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { useChargeHeaderMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useChargeHeaderMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import { AccordionPanel } from "./Accordion/AccordionPanel";

const ChargeBatchUpdatePanel = () => {
    const CHARGEDETAIL_CONSTANT = ChargeDetailConstant.Charge;
    const chargeDetailVM = useChargeDetailVM();
    const messageBarVM = useMessageBarVM();
    const chargeHeaderVM = useChargeHeaderMaintenanceVM();
    const [chargeDetailState, setChargeDetailState] = useChargeDetailTracked();
    const [chargeHeaderState] = useChargeHeaderMaintenanceTracked();
    const {chargeBatchUpdate, selectedChargeDetails} = chargeDetailState;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const closeSlider = useCallback(() => {
        chargeDetailVM.onBatchUpdateCloseClick();
    }, [chargeDetailVM]);

    const adjustmentAction = async(keepBatchUpdatePanel:boolean=false) => {
        if (chargeBatchUpdate.adjustmentActive && chargeBatchUpdate.adjustmentEnable) {
            if (!chargeBatchUpdate.adjustmentType && !chargeBatchUpdate.reasonCode) {
                return;
            }
            // for(let charge of selectedChargeDetails) {

            //     charge.adjType = chargeBatchUpdate.adjustmentType;
            //     charge.reasonCode = chargeBatchUpdate.reasonCode;
            // }
            if (!selectedChargeDetails) return;

            const newChargeDetails = selectedChargeDetails.map(dtl => ({...dtl, 
                reasonCode:chargeBatchUpdate.reasonCode,
                adjType: chargeBatchUpdate.adjustmentType,
            }));
            return await chargeDetailVM.handleAdjustment(newChargeDetails, keepBatchUpdatePanel).then((data) => {
                if (data && data["adjustmentChargeFail"]) {
                    const err:string = data["adjustmentChargeFail"]?.toString()??"";
                    messageBarVM.showError(err.replace("Error:", ""));
                    return;
                } else {                
                    let newSearchCriteria = chargeDetailState.searchCriteria;
                    newSearchCriteria = {...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id??chargeHeaderState?.currentChargeHeader?.id)??0}

                    Promise.allSettled([
                        chargeDetailVM.getChargeDetails(newSearchCriteria),                        
                        chargeHeaderVM.onSearch({
                            ...chargeDetailState.searchCriteria,
                            id: chargeDetailState?.currentChargeHeader?.id??chargeHeaderState?.currentChargeHeader?.id
                        }, EMPTY_SEARCH_MODE_ENTITY, true, true).then(data => {
                            if (data) {
                                setChargeDetailState(prevState => ({
                                    ...prevState,
                                    currentChargeHeader: data[0]
                                }));
                            }
                        })
                    ]).finally(() => {
                        if (data && data["adjustmentChargeFail"]) {
                            const err:string = data["adjustmentChargeFail"]?.toString()??"";
                            messageBarVM.showError(err.replace("Error:", ""));
                        } else {
                            chargeBatchUpdate.emptyIndActive = false;
                            chargeBatchUpdate.emptyIndEnable = false;
                            messageBarVM.showSuccess(MessageConstant.common.OPERATION_DONE_SUCCESSFUL.replace(
                                "{operationDone}", ChargeDetailConstant.Charge.ADJUSTMENT_BUT));
                        }

                        setIsLoading(false);
                        chargeDetailVM.onBatchUpdateCloseClick();
                    });
                }
            }).catch((error) => {
                setIsLoading(false);
                chargeDetailVM.onBatchUpdateCloseClick();
            }).finally(() => {
                setIsLoading(false);
                chargeDetailVM.onBatchUpdateCloseClick();
            });
        }
    }
    
    const batchUpdateAction = async(keepBatchUpdatePanel:boolean=false) => {
        return await chargeDetailVM.onBatchUpdateSave(chargeBatchUpdate, selectedChargeDetails, keepBatchUpdatePanel).then((data) => {
            let newSearchCriteria = chargeDetailState.searchCriteria;
            newSearchCriteria = {...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id??chargeHeaderState?.currentChargeHeader?.id)??0}

            Promise.allSettled([
                chargeDetailVM.getChargeDetails(newSearchCriteria),                        
                chargeHeaderVM.onSearch({
                    ...chargeDetailState.searchCriteria,
                    id: chargeDetailState?.currentChargeHeader?.id??chargeHeaderState?.currentChargeHeader?.id
                }, EMPTY_SEARCH_MODE_ENTITY, true, true).then(data => {
                    if (data) {
                        setChargeDetailState(prevState => ({
                            ...prevState,
                            currentChargeHeader: data[0]
                        }));
                    }
                })
            ]).finally(() => {
                if (data && data["batchUpdteFail"]) {
                    messageBarVM.showError(data["batchUpdteFail"]?.toString());
                } else {
                    chargeBatchUpdate.emptyIndActive = false;
                    chargeBatchUpdate.emptyIndEnable = false;
                    messageBarVM.showSuccess(MessageConstant.common.OPERATION_DONE_SUCCESSFUL.replace(
                        "{operationDone}", ChargeDetailConstant.Charge.BULK_EDIT));
                }

                setIsLoading(false);
                chargeDetailVM.onBatchUpdateCloseClick();
            });
        }).catch((error) => {
            setIsLoading(false);
            chargeDetailVM.onBatchUpdateCloseClick();
        }).finally(() => {
            setIsLoading(false);
            chargeDetailVM.onBatchUpdateCloseClick();
        });
    }

    const validateBundleMaster = async() => {
        if (!!!chargeBatchUpdate.bundleMasterNumber) return true;

        setIsLoading(true);
        return chargeDetailVM.validateMasterBundle(chargeDetailState.selectedChargeDetails, 
            chargeBatchUpdate.bundleMasterNumber??"", chargeBatchUpdate.bundleMasterVisit??"").then((data) => {
            if (data && data["validateMasterBundleFail"]) {
                const err:string = data["validateMasterBundleFail"]?.toString()??"";
                messageBarVM.showError(err.replace("Error:", ""));
                return false;
            }
            return true;
        }).catch((error) => {
            return false;
        })
    }

    const saveButtonClicked = async() => {        
        setIsLoading(true);
        
        try {
            await chargeDetailVM.onValidationBeforeBatchUpdateSave(chargeBatchUpdate).then(data => {
                if (data) {
                    !chargeBatchUpdate.adjustmentActive && //for bundle master no changed
                    chargeBatchUpdate.bundleMasterNoActive && validateBundleMaster().then((data) => {
                        if (data) {
                            batchUpdateAction(true);
                        } else {
                            setIsLoading(false);
                        }
                    });
                    
                    //for normal batch edit 
                    !chargeBatchUpdate.adjustmentActive && !chargeBatchUpdate.bundleMasterNoActive && batchUpdateAction(true);

                    //for adjustment
                    chargeBatchUpdate.adjustmentActive && adjustmentAction(true);
                } else {
                    setIsLoading(false);
                }
            })
        }catch(error) {
            setIsLoading(false);
            chargeDetailVM.onBatchUpdateCloseClick();
        }
    }

    return (
        <div className='side-form-content-wrapper'>
            <div className={'im-flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>
                            {chargeDetailState.isAccTml?CHARGEDETAIL_CONSTANT.ACCOUNT_TERMINAL
                                :chargeDetailState.isAssignOneOffRate?CHARGEDETAIL_CONSTANT.ASSIGN_ONE_OFF_RATE
                                :chargeDetailState.isAdjustment?CHARGEDETAIL_CONSTANT.AJDUSTMENT
                                :chargeDetailState.isUptAbsolutelyCommonAc?CHARGEDETAIL_CONSTANT.UPT_ABSOLUTELY_COMMON_AC
                                :chargeDetailState.isCopyCommonAc?CHARGEDETAIL_CONSTANT.COPY_COMMON_AC
                                :CHARGEDETAIL_CONSTANT.BULK_EDIT}
                        </SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>
            {/* Stack Form Start */}
            {isLoading && 
                <Loader Indicator="Spinner" size="Medium"/>}

            <div className={'add-edit-form'} style={{ height:'72vh', maxHeight:'72vh' , overflow: 'auto'}}>
                <div className="im-flex-row">
                    <div className={'flex-row-item flex-row-item-full-width'}>
                        <AccordionPanel chargeDetailState={chargeDetailState}/>
                    </div>
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={WorkspaceConstant.Common.BUTTON_CANCEL} size={'Small'} theme={'Secondary'} onClick={closeSlider} />
                <HPHButton label={WorkspaceConstant.Common.BUTTON_UPDATE} size={'Small'} theme={'Primary'} onClick={saveButtonClicked} />
            </SidebarActionBar>
            
        </div>
    );
}

export default memo(ChargeBatchUpdatePanel);