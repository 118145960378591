import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { useCallback, useMemo } from "react";
import UpdateCntrStatusPanel from "../LeftPanel/UpdateCntrStatusPanel";
import UpdateVesselVoyageMasterSearchPanel from "../LeftPanel/UpdateVesselVoyageMasterSearchPanel";
import UpdateVesselVoyageMasterTablePanel from "../LeftPanel/UpdateVesselVoyageMasterTablePanel";
import AssignSeqNoPanel from "./AssignSeqNoPanel";
import ChargeBatchUpdatePanel from "./ChargeBatchUpdatePanel";
import CntrInfoPanel from "./CntrInfoPanel";

// interface IContentHtml {
//     leftWidth: string;
//     rightWidth: string;
// }

const ChargeDetailMaintenancePanel = () => {
    const [chargeDetailState] = useChargeDetailTracked();
    const { isShowBatchUpdatePanel, isShowCntrInfoPanel, isShowUpdateVesselVoyageMasterSearchPanel,
        isShowUpdateVesselVoyageMasterTablePanel, isShowAssignSeqNoPanel, isShowUpdateStatus } = chargeDetailState;

    const memoBatchUpdatePanel = useMemo(() => {
        return <ChargeBatchUpdatePanel />
    }, []);

    const memoCntrInfoPanel = useMemo(() => {
        return <CntrInfoPanel />
    }, []);

    const memoUpdateVesselVoyageSearchPanel = useMemo(() => {
        return <UpdateVesselVoyageMasterSearchPanel />
    }, []);

    const memoUpdateVesselVoyageTablePanel = useMemo(() => {
        return <UpdateVesselVoyageMasterTablePanel />
    }, []);

    const memoAssignSeqNoPanel = useMemo(() => {
        return <AssignSeqNoPanel />
    }, []);

    const memoUpdateCntrStatusPanel = useMemo(() => {
        return <UpdateCntrStatusPanel />
    }, []);


    // const getSliderContent = (): IContentHtml => {
    //     if (isShowBatchUpdatePanel || isShowCntrInfoPanel || isShowUpdateVesselVoyageMasterTablePanel || isShowAssignSeqNoPanel) {
    //         return { leftWidth: '0%', rightWidth: '100%' };
    //     } else if ((isShowRightEditPanel || isShowUpdateVesselVoyageMasterSearchPanel)) {
    //         return { leftWidth: '100%', rightWidth: '0%' };
    //     } else {
    //         return { leftWidth: '0%', rightWidth: '0%' };
    //     }
    // }

    // const content: IContentHtml = getSliderContent();

    const memoRightChild = useCallback(() => {
        if (isShowBatchUpdatePanel) {
            return memoBatchUpdatePanel;
        } else if (isShowCntrInfoPanel) {
            return memoCntrInfoPanel;
        } else if (isShowUpdateVesselVoyageMasterTablePanel) {
            return memoUpdateVesselVoyageTablePanel;
        } else if (isShowAssignSeqNoPanel) {
            return memoAssignSeqNoPanel;
        } else if (isShowUpdateVesselVoyageMasterSearchPanel) {
            return memoUpdateVesselVoyageSearchPanel;
        } else if (isShowUpdateStatus) {
            return memoUpdateCntrStatusPanel;
        }
        return <></>
    }, [isShowAssignSeqNoPanel, isShowBatchUpdatePanel, isShowCntrInfoPanel, isShowUpdateStatus,
        isShowUpdateVesselVoyageMasterSearchPanel, isShowUpdateVesselVoyageMasterTablePanel, memoAssignSeqNoPanel,
        memoBatchUpdatePanel, memoCntrInfoPanel, memoUpdateCntrStatusPanel, memoUpdateVesselVoyageSearchPanel,
        memoUpdateVesselVoyageTablePanel]);

    return <div className={`main-comp-wrapper${isShowUpdateVesselVoyageMasterSearchPanel || isShowUpdateStatus ? '' : ' im-hide-side-form-draggable'}`}>
        {/* <SliderPanel
            isOpen={isShowBatchUpdatePanel || isShowCntrInfoPanel ||
                isShowUpdateVesselVoyageMasterTablePanel || isShowAssignSeqNoPanel}
            leftSectionWidth={content.leftWidth}
            rightSectionWidth={content.rightWidth}
            draggable={false}
            leftChildren={isShowUpdateVesselVoyageMasterSearchPanel ? memoUpdateVesselVoyageSearchPanel :
                (isShowUpdateStatus ? memoUpdateCntrStatusPanel : <></>)}
            rightChildren={memoRightChild()}
        /> */}
        {memoRightChild()}
    </div>
}

export default ChargeDetailMaintenancePanel;