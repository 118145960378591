import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { TariffCodeComboxSearchCriteria } from "domain/entity/StandardProposal/TariffCodeComboxSearchCriteria";
import { EMPTY_STANDARD_TARIFF_CODE_ENTITY, StandardTariffCodeEntity } from "domain/entity/TariffCode/StandardTariffCodeEntity";
import { TariffCodeSearchCriteria } from "domain/entity/TariffCode/TariffCodeSearchCriteria";
import { TariffSchemeEntity } from "domain/entity/TariffCode/TariffSchemeEntity";
import _ from "lodash";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import tariffAxiosInstance from "../axios/tariffAxiosInstance";
import { StandardTariffCodeRepository } from "./StandardTariffCodeRepo";

export const StandardTariffCodeRepoImpl = (): StandardTariffCodeRepository => {
    const url = '/v1/standardTariffCode';
    const urlActive = '/v1/standardTariffCodeForComboBox';
    const tariffByTypeCodeUrl = '/v1/standardTariffCodeByTypeAndCode';
    const urlTarScheme = '/v1/tariffScheme';
    const urlUpload = '/v1/standardTariffCodeUpload';

    /**
     * Get all standard tariff codes, active and un-active
     * @returns Active and un-active
     */
    const findAllActiveUnActiveStandardTariffCodes = async (): Promise<StandardTariffCodeEntity[]> => {
        return axiosGetData(tariffAxiosInstance, url, []).then(res => {
            return _.sortBy(res.data, ["seq"]);
        }).catch(err => {
            return [];
        });
    }

    /**
     * Get all active standard tariff codes
     * @returns All active 
     */
    const getAllStandardTariffCodes = async (): Promise<StandardTariffCodeEntity[]> => {
        return axiosGetData(tariffAxiosInstance, urlActive, []).then(res => {
            return _.sortBy(res.data, ["seq"]);
        }).catch(err => {
            return [];
        });
    }

    const getStandardTariffCodeByKey = async (key: string): Promise<StandardTariffCodeEntity> => {
        return axiosGetData(tariffAxiosInstance, `${url}/${key}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const createNewStandardTariffCode = async (newData: StandardTariffCodeEntity): Promise<ResponseEntity> => {
        return await axiosPostData(tariffAxiosInstance, `${url}`, newData)
    }

    const updateStandardTariffCode = async (updatedData: StandardTariffCodeEntity): Promise<ResponseEntity> => {
        return await axiosPutData(tariffAxiosInstance, `${url}`, updatedData)
    }

    const deleteStandardTariffCodeByKey = async (key: string): Promise<boolean> => {
        return axiosDeleteData(tariffAxiosInstance, `${url}`, key).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    const getTariffByTypeAndCode = async (tariffType: string, tariffCode: string): Promise<StandardTariffCodeEntity> => {
        const bo: StandardTariffCodeEntity = {
            ...EMPTY_STANDARD_TARIFF_CODE_ENTITY,
            tariffCode: tariffCode,
            tariffType: tariffType
        }
        return axiosPostData(tariffAxiosInstance, `${tariffByTypeCodeUrl}`, bo).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const getTariffCodeForStdProposal = async (criteria: TariffCodeComboxSearchCriteria): Promise<string[]> => {
        const newUrl = "/v1/getTariffCodeForStdProposal"
        return axiosPostData(tariffAxiosInstance, `${newUrl}`, criteria).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const searchTariffCodeByCriteria = async (criteria: TariffCodeSearchCriteria): Promise<StandardTariffCodeEntity[]> => {
        const newUrl = "/v1/searchTariffCodeByCriteria"
        return axiosPostData(tariffAxiosInstance, `${newUrl}`, criteria).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const onApply = async (updatedRows: StandardTariffCodeEntity[]): Promise<ResponseEntity> => {
        const applyUrl = "/v1/tariffCodeApply"
        return await axiosPostData(tariffAxiosInstance, `${applyUrl}`, updatedRows);
    }

    const getTariffScheme = async (): Promise<TariffSchemeEntity[]> => {
        return axiosGetData(tariffAxiosInstance, urlTarScheme, []).then(res => {
            return _.sortBy(res.data, ["tariffSchemeCode"]);
        }).catch(err => {
            return [];
        });
    }

    const uploadData = async (csvData: StandardTariffCodeEntity[]): Promise<ResponseEntity> => {
        return await axiosPostData(tariffAxiosInstance, `${urlUpload}`, csvData)
    }

    const getTariffForTariffNature = async (): Promise<{ [key: string]: string[] }> => {
        const newUrl = "/v1/getTariffForTariffNature"
        return axiosPostData(tariffAxiosInstance, `${newUrl}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    return {
        findAllActiveUnActiveStandardTariffCodes: findAllActiveUnActiveStandardTariffCodes,
        getAllStandardTariffCodes: getAllStandardTariffCodes,
        getStandardTariffCodeByKey: getStandardTariffCodeByKey,
        createNewStandardTariffCode: createNewStandardTariffCode,
        updateStandardTariffCode: updateStandardTariffCode,
        deleteStandardTariffCodeByKey: deleteStandardTariffCodeByKey,
        getTariffByTypeAndCode: getTariffByTypeAndCode,
        getTariffCodeForStdProposal: getTariffCodeForStdProposal,
        searchTariffCodeByCriteria: searchTariffCodeByCriteria,
        onApply: onApply,
        getTariffScheme: getTariffScheme,
        uploadData: uploadData,
        getTariffForTariffNature: getTariffForTariffNature,
    }
}